
.App {
  text-align: center;
  font-family:roboto;
}

.App-header {
  background-image: url("./images/halfdome.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 127%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family:roboto;
}

.App-top {
  position: fixed;
  top: 0px;
  left: 10%;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(240,240,240,0.01);
}

.App-bottom {
  position: absolute;
  bottom: 10px;
  left: 10%;
  padding: 10px;
  color: black;
  background-color: rgba(240,240,240,0.6);
}

.App-link {
  color: #61dafb;
}

