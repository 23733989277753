.Form {
  text-align: center;
}

.Form-contactus {
  background-image: linear-gradient(rgba(200,200,200,0.8), rgba(250,250,250,0.4));
  padding: 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 16px;
}

.Form-hide {
  display: none;
}

.Form-show {
  display: inline;
}
